export const requiredRule = (v: unknown) =>
  !!v || v === 0 || v === false || "Required field";
export const middleInitialRule = (v: string) =>
  !v || v.length == 1 || "Max 1 character";
export const emailRule = (v: string) =>
  !v ||
  /^[\w\-.]+@([\w-]+\.)+[\w-]{2,}$/.test(v) ||
  "Valid email address required";
export const phoneRule = (v: string) =>
  !v ||
  v.replace(/[^\d]/g, "").length === 10 ||
  "10-digit phone number required";
export const zipCodeRule = (v: string) =>
  !v ||
  v.length == 5 ||
  v.length == 10 ||
  // Allow 9 digit zip code with hyphen
  /^\d{5}-\d{4}$/.test(v) ||
  "Must be 5 or 9 digit zip code";
export const last4DigitsOfSSNRule = (v: string) =>
  !v || v.length == 4 || "Enter only the last 4 digits of the SSN";
export const socialSecurityNumberRule = (v: string) =>
  !v || v.length == 9 || "Please use the full 9 digit SSN";
export const dateRule = (v: string) =>
  !v ||
  /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(v) ||
  "Date must be in the format MM/DD/YYYY";
