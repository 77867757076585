import { PDFDocument } from "pdf-lib";
import {
  base64ToArrayBuffer,
  dataUrlToInfo,
  urlToArrayBuffer,
} from "./converters";
import { SignatureInfo } from "@/types/Signature";

export async function addSignatureToPdf(
  pdfBase64: string,
  signatureUrl: string,
  signatureInfo: SignatureInfo | undefined
) {
  if (!signatureInfo || !signatureUrl) return pdfBase64;

  const type = "application/pdf";
  const pdfBytes: ArrayBuffer = await base64ToArrayBuffer(pdfBase64, type);
  const pdf = await PDFDocument.load(pdfBytes);

  const signatureBytes: ArrayBuffer = await urlToArrayBuffer(signatureUrl);
  const image = await pdf.embedPng(signatureBytes);

  const page = await pdf.getPages()[signatureInfo.page];
  const { x, y, x2, y2 } = signatureInfo;

  if (x2 && y2) {
    page.drawImage(image, { x: x2, y: y2, width: 100, height: 50 });
  }

  page.drawImage(image, { x, y, width: 100, height: 50 });

  return await pdf.saveAsBase64();
}

export async function appendPdf(base64: string, receiptUrl: string) {
  const pdfBytes = await base64ToArrayBuffer(base64, "application/pdf");
  const receiptBytes = await urlToArrayBuffer(receiptUrl);

  const pdf = await PDFDocument.load(pdfBytes);
  const receiptPdf = await PDFDocument.load(receiptBytes);

  const receiptPages = await pdf.copyPages(receiptPdf, [0]);
  receiptPages.forEach((page) => {
    pdf.addPage(page);
  });

  return await pdf.saveAsBase64();
}

export async function appendImageOrPdf(pdfBase64: string, imageUrl: string) {
  try {
    const pdfDocBase = await PDFDocument.load(pdfBase64);
    const { content, fileExtension } = dataUrlToInfo(imageUrl);

    const pageWidth = 612;
    const pageHeight = 792;
    pdfDocBase
      .getPages()
      .forEach((page) => page.setSize(pageWidth, pageHeight));

    if (fileExtension === "pdf") {
      const pdfDocImage = await PDFDocument.load(content);

      pdfDocImage
        .getPages()
        .forEach((page) => page.setSize(pageWidth, pageHeight));

      const copiedPages = await pdfDocBase.copyPages(
        pdfDocImage,
        pdfDocImage.getPageIndices()
      );

      copiedPages.forEach((copiedPage) => pdfDocBase.addPage(copiedPage));

      const modifiedPdfBytes = await pdfDocBase.saveAsBase64();
      return modifiedPdfBytes;
    }

    const pdfDoc = pdfDocBase;

    const contentBytes = Uint8Array.from(atob(content), (c) => c.charCodeAt(0));
    const embeddedContent =
      fileExtension === "jpeg" || fileExtension === "jpg"
        ? await pdfDoc.embedJpg(contentBytes)
        : await pdfDoc.embedPng(contentBytes);

    normalizePageDimensions(pdfDoc, pageWidth, pageHeight);
    const page = pdfDoc.addPage([pageWidth, pageHeight]);

    page.drawImage(embeddedContent, {
      x: 60,
      y: 400,
      width: embeddedContent.width / 3,
      height: embeddedContent.height / 3,
    });

    const modifiedPdfBytes = await pdfDoc.saveAsBase64();

    return modifiedPdfBytes;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
}

async function normalizePageDimensions(
  pdfDoc: PDFDocument,
  width: number,
  height: number
) {
  for (const page of pdfDoc.getPages()) {
    page.setSize(width, height);
  }
}

export async function flattenPDFForm(base64: string) {
  const pdfBytes = await base64ToArrayBuffer(base64, "application/pdf");

  const pdfDoc = await PDFDocument.load(pdfBytes);

  const pageWidth = 612;
  const pageHeight = 792;

  const form = pdfDoc.getForm();
  form.flatten();
  await normalizePageDimensions(pdfDoc, pageWidth, pageHeight);

  const flattenedPdfBytes = await pdfDoc.saveAsBase64();

  return flattenedPdfBytes;
}
