import { useBucketStore } from "@/stores/Bucket";
import { fileToDataUrl } from "@/utils/converters";

export default class MyFile {
  private file: File | undefined;
  private isSaved = false;
  private key: string;
  private fileType: string;
  private store;

  constructor(key: string, type: string) {
    this.key = key;
    this.store = useBucketStore();
    this.fileType = type;
  }

  getKey() {
    return this.key;
  }

  getFile() {
    return this.file;
  }

  getType() {
    if (!this.file) {
      return "";
    }
    return this.fileType != "" ? this.fileType : this.file.type;
  }

  setFile(file: File) {
    this.file = file;
    this.isSaved = false;
  }

  async save() {
    if (!this.isSaved && this.file) {
      const parts = this.file.type.split("/");
      const fileExtension = "." + parts[1];

      await this.store.saveFile(
        this.key + fileExtension,
        this.file,
        this.fileType
      );
      this.isSaved = true;
    }
  }

  async load(key: string, type: string) {
    const response = await this.store.getSignedURlToGetFile(key);

    const url = response?.data.url;
    this.file = await this.store.getFile(url, key, type);
  }

  async toDataUrl() {
    if (!this.file) {
      return "";
    }
    return await fileToDataUrl(this.file);
  }

  async getInfo() {
    if (!this.file) {
      return undefined;
    }
    const parts = this.file.type.split("/");
    const fileExtension = "." + parts[1];
    return {
      key: this.getKey() + fileExtension,
      file_type: this.getType(),
    };
  }
}
