import { defineStore } from "pinia";
import axios from "axios";

const url = process.env.VUE_APP_BASE_URL;
const AUTH_URL = url + "/access";
const API_KEY = process.env.VUE_APP_BACKEND_API_KEY as string;
const config = { headers: { "x-api-key": API_KEY } };

export const useAuthStore = defineStore("auth", {
  actions: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async verifyAccess(data: unknown) {
      return await axios.post(AUTH_URL + "/auth", data, config);
    },
  },
});
