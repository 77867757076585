import { createFileFromBinaryContent } from "@/utils/converters";
import axios, { AxiosResponse } from "axios";
import { defineStore } from "pinia";

const url = process.env.VUE_APP_BASE_URL + "/documents/";
const API_KEY = process.env.VUE_APP_BACKEND_API_KEY as string;
const config = { headers: { "x-api-key": API_KEY } };

export const useBucketStore = defineStore("bucket", {
  actions: {
    async getFile(bucketURL: string, key: string, type: string) {
      try {
        if (bucketURL) {
          const awsConfig = {
            responseType: "arraybuffer" as const, // Use const assertion
            headers: {
              "Content-Type": type,
            },
          };

          const response: AxiosResponse<ArrayBuffer> = await axios.get(
            bucketURL,
            awsConfig
          );

          if (response.data) {
            const uint8Array = new Uint8Array(response.data);
            return await createFileFromBinaryContent(uint8Array, key, type);
          }
        }
      } catch {
        console.log("Error loading file");
      }
    },

    async saveFile(key: string, file: unknown, type: string) {
      const awsConfig = {
        headers: {
          "Content-Type": type,
        },
      };
      try {
        const bucketURL = await this.getSignedURlToPutFile(key, type);
        return await axios.put(bucketURL?.data.url, file, awsConfig);
      } catch {
        console.log("Error saving file");
      }
    },

    async getSignedURlToGetFile(key: string) {
      try {
        return await axios.post(url + "get-url", { key }, config);
      } catch (e) {
        console.log("Error getting URL to get file");
      }
    },

    async getSignedURlToPutFile(key: string, type: string) {
      try {
        return await axios.post(url + "update-url", { key, type }, config);
      } catch {
        console.log("Error getting URL to save file");
      }
    },
  },
});
