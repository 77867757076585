import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { createVuetify, type ThemeDefinition } from "vuetify";
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";

const theme: ThemeDefinition = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    primary: "#732779",
    secondary: "#FFFFFF",
    tertiary: "#fd408f",
    darkGray: "#E0E0E0",
    lightGray: "#6B7588",
    black: "#424242",
    error: "#FE0000",
    info: "#2196F3",
    success: "#4DA95E",
    warning: "#FE0000",
    binkeyGray: "#6B7588",
    binkeyPink: "#FD408F",
  },
};

export const vuetifyConfig = {
  components: {
    ...components,
  },
  directives,
  theme: {
    defaultTheme: "theme",
    themes: { theme },
  },
  lang: {
    locales: {
      en: {
        // Define your language options here
        dateFormat: "MM/DD/YYYY", // Set the date format
      },
    },
    current: "en", // Set the default locale
  },
};

const vuetify = createVuetify(vuetifyConfig);
export default vuetify;
