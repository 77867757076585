import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.snackbar) = $event)),
      color: "red",
      timeout: "2000"
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          color: "white",
          variant: "text",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.snackbar = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" X ")
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.message) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}