import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import i18n from "@/i18n";
import vuetify from "@/vuetify";
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import { AwsConfigAuth } from "./amplify/awsconfig";
import { Amplify } from "aws-amplify";
import VOtpInput from "vue3-otp-input";
import posthogPlugin from "./plugins/posthog";

const app = createApp(App);
const pinia = createPinia();

Amplify.configure({
  Auth: AwsConfigAuth,
});

app.use(posthogPlugin);
app.use(pinia);
app.use(vuetify);
app.use(i18n);
app.use(router);
app.use(VOtpInput);

app.mount("#app");
