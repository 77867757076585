import { defineComponent, ref } from "vue";
import { provide } from "vue";

export default defineComponent({
  async created() {
    // Include the JavaScript file
  },
  setup() {
    const message = ref<string>("");
    const snackbar = ref<boolean>(false);

    const showNotification = (errorMessage: string) => {
      message.value = errorMessage;
      snackbar.value = true;
    };

    provide("show-error-notification", showNotification);
    return { snackbar, message };
  },
});
