import Invoice from "@/types/FsaInvoice";
import Item from "@/types/FsaItem";

type InvoicePerClaim = {
  items: Array<Item>;
  bucketLinks: Array<string>;
  bucketKeys: Array<string>;
};

export function allItems(invoices: Array<Invoice>): Array<Item> {
  return invoices.reduce(
    (list: Item[], invoice: Invoice) => list.concat(getItemsWithDate(invoice)),
    []
  );
}

export function getItemsWithDate(invoice: Invoice) {
  return invoice.items.map((item) => ({ ...item, date: invoice.date }));
}

export function selectedItems(items: Array<Item>): Array<Item> {
  return items.filter((item) => item.value);
}

export function selectedInvoices(invoices: Array<Invoice>): Array<Invoice> {
  return invoices.filter((invoice) => invoice.items.some((item) => item.value));
}

export async function divideItems(
  invoices: Array<Invoice>,
  maxItems: number
): Promise<InvoicePerClaim[]> {
  const invoicesPerClaimArray: Array<InvoicePerClaim> = [];

  // Initialize variables to track the current claim
  let currentClaimItems: Array<Item> = [];
  let currentClaimBucketLinks: Set<string> = new Set();
  let currentClaimBucketKeys: Set<string> = new Set();

  // Iterate through the invoices
  for (const invoice of invoices) {
    const items = getItemsWithDate(invoice);
    const fileUrl = invoice.fileUrl;
    const fileKey = invoice.s3BucketKey;

    for (const item of items) {
      currentClaimItems.push(item);
      currentClaimBucketLinks.add(fileUrl || "");
      currentClaimBucketKeys.add(fileKey || "");

      // If the current claim has `maxItems` items, save it
      if (currentClaimItems.length === maxItems) {
        invoicesPerClaimArray.push({
          items: [...currentClaimItems],
          bucketLinks: [...currentClaimBucketLinks],
          bucketKeys: [...currentClaimBucketKeys],
        });

        // Reset for the next claim
        currentClaimItems = [];
        currentClaimBucketLinks = new Set();
        currentClaimBucketKeys = new Set();
      }
    }
  }

  // Save any remaining items as the last claim
  if (currentClaimItems.length > 0) {
    invoicesPerClaimArray.push({
      items: [...currentClaimItems],
      bucketLinks: [...currentClaimBucketLinks],
      bucketKeys: [...currentClaimBucketKeys],
    });
  }

  return invoicesPerClaimArray;
}

export async function divideByClaims(
  invoices: Array<Invoice>,
  maxItems: number
): Promise<InvoicePerClaim[]> {
  const invoicesPerClaimArray: Array<InvoicePerClaim> = [];

  // Initialize variables to track the current claim
  let currentClaimItems: Array<Item> = [];
  const currentClaimBucketLinks: Set<string> = new Set();
  let currentClaimBucketKeys: Set<string> = new Set();

  // Iterate through the invoices
  for (const invoice of invoices) {
    const items = getItemsWithDate(invoice);
    const fileUrl = invoice.fileUrl;
    const fileKey = invoice.s3BucketKey;

    for (const item of items) {
      currentClaimItems.push(item);
      currentClaimBucketLinks.add(fileUrl || "");
      currentClaimBucketKeys.add(fileKey || "");

      // If the current claim has `maxItems` items, save it
      if (currentClaimItems.length === maxItems) {
        invoicesPerClaimArray.push({
          items: [...currentClaimItems],
          bucketLinks: [],
          bucketKeys: [...currentClaimBucketKeys],
        });

        // Reset for the next claim
        currentClaimItems = [];
        currentClaimBucketKeys = new Set();
      }
    }
  }

  // Save any remaining items as the last claim
  if (currentClaimItems.length > 0) {
    invoicesPerClaimArray.push({
      items: [...currentClaimItems],
      bucketLinks: [],
      bucketKeys: [...currentClaimBucketKeys],
    });
  }

  return invoicesPerClaimArray;
}

export async function removeIndexSuffix(
  jsonObj: { [key: string]: string },
  index: number
): Promise<{ [key: string]: string }> {
  const modifiedObj: { [key: string]: string } = {};

  for (const key in jsonObj) {
    if (Object.hasOwnProperty.call(jsonObj, key)) {
      const modifiedKey = key.replace(`____${index}`, ""); // Remove the "-index" suffix
      modifiedObj[modifiedKey] = jsonObj[key];
    }
  }

  return modifiedObj;
}
