import { isTokenValid } from "@/utils/auth";
import {
  type NavigationGuardNext,
  type RouteLocationNormalized,
} from "vue-router";

const TEST_MODE = (process.env.VUE_APP_TEST_MODE as string) === "true";

export function authGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const isLogged = isTokenValid();

  if (process.env.VUE_APP_TEST_MODE == "true") {
    next();
  } else if (to.meta.requiresAuth && !isLogged && !TEST_MODE) {
    next({ path: "/" });
  } else {
    next();
  }
}
