import axios from "axios";
import { defineStore } from "pinia";

const URL = process.env.VUE_APP_BASE_URL + "/providers";
const API_KEY = process.env.VUE_APP_BACKEND_API_KEY as string;
const config = { headers: { "x-api-key": API_KEY } };

type AddNotSupportedParams = {
  name: string;
  userEmail: string;
  sessionLink: string;
  reimbursementType: string;
  sessionId?: string;
};

export const useProviderStore = defineStore("provider", {
  actions: {
    async addNotSupported(body: AddNotSupportedParams) {
      return await axios.post(URL + "/not-supported", body, config);
    },

    async listFsaProviders() {
      return await axios.get(URL + "/listFsaProviders", config);
    },

    async listVisionProviders() {
      return await axios.get(URL + "/listVisionProviders", config);
    },
  },
});
