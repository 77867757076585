import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ma-4 d-flex justify-center" }
const _hoisted_2 = {
  ref: "canvas",
  class: "border rounded-xl",
  style: {"background-color":"white"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    variant: "outlined",
    style: {"border":"1px solid #e0e0e0","background-color":"#f5f5f5"},
    class: "pa-0"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("canvas", _hoisted_2, null, 512)
      ]),
      _createVNode(_component_v_card_actions, { class: "d-flex justify-center card-background" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            onClick: _ctx.clear,
            variant: "outlined",
            class: "text-capitalize",
            width: "180",
            height: "45"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Clear ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}