<template>
  <div class="claim-card-container">
    <div variant="flat" class="claim-confirmation-container">
      <img src="../../assets/submitted-icon.svg" alt="submitted" />
      <h2>
        Congrats! You have successfully submitted a claim for reimbursement.
      </h2>
      <p class="claim-card-description">
        Your insurance administrator will review your claim and send a
        reimbursement check in the mail if it is approved. Depending on your
        insurance provider, this process can take up to a few weeks.
      </p>
      <p class="claim-card-description">You can now close this page.</p>
    </div>
  </div>
</template>
