import { loadVGSCollect } from "@vgs/collect-js";
import { toRaw } from "vue";

const VGS_VAULT_ID = process.env.VUE_APP_VGS_VAULT_ID as string;
const VGS_ENVIRONMENT = process.env.VUE_APP_VGS_ENVIRONMENT as string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const collect: any = await loadVGSCollect({
  vaultId: VGS_VAULT_ID,
  environment: VGS_ENVIRONMENT,
  version: "2.18.0",
});

export default class VgsForm {
  private form;

  constructor() {
    this.form = collect.init();
  }

  getForm() {
    return this.form;
  }

  toRaw() {
    return toRaw(this.form);
  }
}
