import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/home/HomeView.vue";
import { authGuard } from "@/guards/AuthGuard";
import NotFound from "@/views/not-found/NotFound.vue";
import OTPView from "@/views/security-code-view/SecurityCodeView.vue";
import FsaFlow from "@/views/forms/FsaFlow.vue";
import VisionFlow from "@/views/forms/VisionFlow.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/vision",
    name: "vision",
    component: VisionFlow,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/fsa/:sessionId",
    name: "fsa",
    component: FsaFlow,
  },

  {
    path: "/start/:sessionId",
    name: "start",
    component: HomeView,
  },
  {
    path: "/:pathMatch(.*)",
    name: "notFound",
    component: NotFound,
  },
  {
    path: "/otp-view",
    name: "otp-view",
    component: OTPView,
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

router.beforeEach(authGuard);

export default router;
