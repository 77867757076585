import { defineComponent } from "vue";
import { useAuthStore } from "@/stores/Auth";
import router from "@/router";
import { login } from "@/utils/auth";
import { useRoute } from "vue-router";
import i18n from "@/i18n";
import useDocumentStore from "@/stores/Document";

export default defineComponent({
  data() {
    return {
      sessionId: useRoute().params.sessionId as string,
      isLoading: true,
      title: "Verifying Information",
      message: "Please wait while we ensure the accuracy of the data",
      contact: "",
    };
  },

  methods: {
    showErrorMessage(errorKey: string) {
      this.isLoading = false;
      this.title = "Oops!";
      this.contact = "support@binkey.com";
      this.message = i18n.global.t(errorKey);
    },

    async loginAndRedirect(email: string, flow: string) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const from = urlParams.get("from") || "";
      await useDocumentStore().linkAccess(this.sessionId, from);
      login(this.sessionId, email, false, flow);
      this.isLoading = false;
      router.replace({ name: "otp-view" });
    },
  },
  async mounted() {
    try {
      useAuthStore()
        .verifyAccess({
          sessionId: this.sessionId,
        })
        .then((res) => {
          setTimeout(async () => {
            this.loginAndRedirect(res.data.email, res.data.insuranceType);
          }, 3000);
        })
        .catch((e) => {
          setTimeout(() => {
            if (
              e.response.data.message === "Session has already been submitted"
            ) {
              this.showErrorMessage("start.errorSessionAlreadySubmited");
            } else {
              this.showErrorMessage("start.error");
            }
          }, 3000);
        });
    } catch (error) {
      this.showErrorMessage("start.error");
    }
  },
});
