import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "center-container" }
const _hoisted_2 = { class: "center-content" }
const _hoisted_3 = { class: "loading-title" }
const _hoisted_4 = { class: "loading-message" }
const _hoisted_5 = { class: "contact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("h3", _hoisted_4, [
        _createTextVNode(_toDisplayString(_ctx.message) + " ", 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.contact), 1)
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_v_progress_circular, {
            key: 0,
            size: 70,
            width: 7,
            color: "primary",
            indeterminate: ""
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}