import axios from "axios";
import { defineStore } from "pinia";

const URL = process.env.VUE_APP_BASE_URL + "/session";
const API_KEY = process.env.VUE_APP_BACKEND_API_KEY as string;
const config = { headers: { "x-api-key": API_KEY } };

export const useSessionStore = defineStore("session", {
  actions: {
    async getSessionFromVgs(sessionId: string) {
      try {
        const vaultId = process.env.VUE_APP_VGS_VAULT_ID as string;
        const environment = process.env.VUE_APP_VGS_ENVIRONMENT as string;

        const url = `${vaultId}.${environment}.verygoodproxy.com`;
        return await axios.post(
          `https://${url}/session/get`,
          { sessionId },
          config
        );
      } catch (e) {
        console.error(e);
      }
    },
    async getSession(sessionId: string) {
      try {
        return await axios.post(URL + "/get", { sessionId: sessionId }, config);
      } catch (e) {
        console.error(e);
      }
    },
    async saveSession(claimInformation: unknown) {
      try {
        return await axios.put(URL + "/update", claimInformation, config);
      } catch (e) {
        console.error(e);
      }
    },
    async updateLastActivity(sessionId: string) {
      axios.patch(URL + "/last-activity", { sessionId }, config);
    },
    async updateHasPassedOTP(sessionId: string) {
      axios.patch(URL + "/has-passed-otp", { sessionId }, config);
    },
  },
});
