import axios from "axios";
import { defineStore } from "pinia";
import Contact from "@/types/Contact";

const URL = process.env.VUE_APP_BASE_URL;
const API_KEY = process.env.VUE_APP_BACKEND_API_KEY as string;

const POSTGRID_URL_CONTACTS = process.env
  .VUE_APP_POSTGRID_API_LINK_CONTACTS as string;

const POSTGRID_SANDBOX = process.env.VUE_APP_POSTGRID_SANDBOX === "true";

const POSTGRID_API_KEY = POSTGRID_SANDBOX
  ? process.env.VUE_APP_POSTGRID_API_KEY_DEV_MODE
  : process.env.VUE_APP_POSTGRID_API_KEY_LIVE_MODE;

const config = { headers: { "x-api-key": API_KEY } };

export const useDocumentStore = defineStore("claim delivery services", {
  actions: {
    async sendEmail(sessionId: string) {
      return await axios.post(URL + "/access/email", { sessionId }, config);
    },

    async linkAccess(sessionId: string, linkSource: string) {
      return await axios.post(
        URL + "/access/link-access",
        { sessionId, linkSource },
        config
      );
    },

    async sendToEmail(base64document: string, account: object, email: string) {
      return await axios.post(
        URL + "/documents/sendToEmail",
        { document: base64document, account, email },
        config
      );
    },

    async getPDF(sessionId: string, workflow: string) {
      return await axios.post(
        URL + "/documents/get-claim",
        { sessionId, workflow },
        config
      );
    },

    async getPDFFSA(sessionId: string, claimsLenght: number, workflow: string) {
      return await axios.post(
        URL + "/documents/get-fsa-claim",
        { sessionId, claimsLenght, workflow },
        config
      );
    },

    async sendToFax(base64document: string, account: object, fax: string) {
      return await axios.post(
        URL + "/documents/sendToFax",
        { document: base64document, account, fax },
        config
      );
    },

    async createContact(contactInfo: Contact) {
      const headers = {
        "x-api-key": POSTGRID_API_KEY,
        "Content-Type": "application/x-www-form-urlencoded",
      };

      return await axios.post(
        POSTGRID_URL_CONTACTS,
        {
          country: "US",
          countryCode: "US",
          skipVerification: true,
          ...contactInfo,
        },
        { headers }
      );
    },

    async submitClaim(sessionId: string) {
      return await axios.post(URL + "/submit/submit", { sessionId }, config);
    },

    async submitDocument(base64: string, sessionId: string) {
      return await axios.post(
        URL + "/submit/document",
        { base64, sessionId },
        config
      );
    },
  },
});

export default useDocumentStore;
