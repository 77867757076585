
import { fileToDataUrl } from "@/utils/converters";
import { ref, computed, defineComponent, onMounted } from "vue";
import { VFileInput } from "vuetify/lib/components/index.mjs";
import PreviewPdf from "./PreviewPdf.vue";

export default defineComponent({
  emits: ["update:file", "file-size-exceeded"],
  props: {
    label: String,
    file: File,
    modelValue: String,
    fileMBLimitSize: {
      type: Number,
      default: 10,
    },
    required: Boolean,
  },
  setup(props, { emit }) {
    const fileInputRef = ref<VFileInput | null>(null);
    const url = ref<string | null>(props.modelValue || null);
    const maxSize = props.fileMBLimitSize * 1024 * 1024;
    const isPdf = ref(url.value?.includes("application/pdf") || false);

    const showSizeError = ref(false);
    const setFileUrl = async (event: Event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file && file.size > maxSize) {
        showSizeError.value = true;
        emit("file-size-exceeded");
        return;
      } else {
        showSizeError.value = false;
      }
      emit("update:file", file);
      isPdf.value = file?.type === "application/pdf";
      if (file) {
        try {
          const dataUrl = await fileToDataUrl(file);
          url.value = dataUrl;
        } catch (error) {
          console.error(error);
          // Handle the error as needed
        }
      } else {
        url.value = null;
      }
    };

    const clear = () => {
      url.value = null;
      emit("update:file", null);
    };
    const click = () => {
      if (fileInputRef.value) {
        fileInputRef.value.click();
      }
    };
    let fileModel = computed<File[] | undefined>(() => {
      if (url.value && props.file) {
        return [new File([url.value], props.file.name)];
      } else {
        return undefined;
      }
    });
    const requiredRule = () =>
      (fileModel.value !== undefined && fileModel.value.length > 0) ||
      "Required field";
    const rules = props.required ? [requiredRule] : [];
    onMounted(async () => {
      if (props.file) {
        const initialFile = props.file;
        url.value = await fileToDataUrl(initialFile);
        isPdf.value = url.value?.includes("application/pdf") || false;
        fileModel = computed<File[] | undefined>(() => {
          if (url.value && props.file) {
            return [new File([url.value], props.file.name)];
          } else {
            return undefined;
          }
        });
      }
    });
    return {
      fileModel,
      url,
      isPdf,
      setFileUrl,
      clear,
      click,
      fileInputRef,
      rules,
      showSizeError,
    };
  },
  components: { PreviewPdf },
});
