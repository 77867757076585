type StateDisclaimer = {
  state: string;
  text: string;
};

export const disclaimers: Array<StateDisclaimer> = [
  {
    state: "General Fraud Warning",
    text: "Any person who knowingly, and with intent to injure, defraud or deceive any insurer or insurance company, files a statement of claim containing any materially false, incomplete, or misleading information or conceals any fact material thereto, may be guilty of a fraudulent act, may be prosecuted under state law and may be subject to civil and criminal penalties. In addition, any insurer or insurance company may deny benefits if false information materially related to a claim are provided by the claimant.",
  },
  {
    state: "Alabama",
    text: "Any person who knowingly presents a false or fraudulent claim for payment of loss or benefit or who knowingly presents false information in an application for insurance is guilty of a crime and may be subject to restitution, fines or confinement in prison, or any combination thereof.",
  },
  {
    state: "Alaska",
    text: "A person who knowingly and with intent to injure, defraud, or deceive an insurance company files a claim containing false, incomplete, or misleading information may be prosecuted under state law.",
  },
  {
    state: "Arizona",
    text: "For your protection Arizona law requires the following statement to appear on this form. Any person who knowingly presents a false or fraudulent claim for payment of a loss is subject to criminal and civil penalties.",
  },
  {
    state: "Arkansas, Louisiana, Rhode Island, West Virginia",
    text: "Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly present false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.",
  },
  {
    state: "California",
    text: "For your protection California law requires the following to appear on this form: Any person who knowingly presents false or fraudulent claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.",
  },
  {
    state: "Colorado",
    text: "It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award payable from insurance proceeds shall be reported to the Colorado division of insurance within the department of regulatory agencies.",
  },
  {
    state: "Delaware",
    text: "Any person who knowingly, and with intent to injure, defraud or deceive any insurer, files a statement of claim containing any false, incomplete or misleading information is guilty of a felony.",
  },
  {
    state: "District of Columbia",
    text: "WARNING: It is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related to a claim was provided by the applicant.",
  },
  {
    state: "Florida",
    text: "Any person who knowingly, and with intent to injure, defraud or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony of the third degree.",
  },
  {
    state: "Georgia, Vermont",
    text: "Any person who with intent to defraud or knowing that he/she is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement may be guilty of insurance fraud.",
  },
  {
    state: "Hawaii",
    text: "For your protection, Hawaii law requires you to be informed that presenting a fraudulent claim for payment of a loss or benefit is a crime punishable by fines or imprisonment, or both.",
  },
  {
    state: "Idaho",
    text: "Any person who knowingly, and with intent to defraud or deceive any insurance company, files a statement or claim containing any false, incomplete, or misleading information is guilty of a felony.",
  },
  {
    state: "Indiana",
    text: "A person who knowingly, and with intent to defraud an insurer files a statement of claim containing any false, incomplete, or misleading information commits a felony.",
  },
  {
    state: "Kansas",
    text: "Any person who with intent to defraud or knowing that he or she is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement may be guilty of insurance fraud as determined by a court of law.",
  },
  {
    state: "Kentucky",
    text: "Any person who knowingly and with intent to defraud any insurance company or other person files a statement of claim containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime.",
  },
  {
    state: "Maine, Tennessee, Washington",
    text: "It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines, or a denial of insurance benefits.",
  },
  {
    state: "Maryland",
    text: "Any person who knowingly and willfully presents a false or fraudulent claim for payment of a loss or benefit or who knowingly and willfully presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.",
  },
  {
    state: "Massachusetts",
    text: "Any person who knowingly and with intent to defraud any insurance company or another person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading information concerning any fact material thereto, may be committing a fraudulent insurance act, which may be a crime and may subject the person to criminal and civil penalties.",
  },
  {
    state: "Minnesota",
    text: "A person who files a claim with intent to defraud or helps commit a fraud against an insurer is guilty of a crime.",
  },
  {
    state: "Nebraska",
    text: "Any person who, with intent to defraud or knowing that he or she is facilitating a fraud against an insurer, submits an application or files a claim containing false, incomplete or misleading information is guilty of insurance fraud.",
  },
  {
    state: "New Hampshire",
    text: "Any person who with a purpose to injure, defraud, or deceive any insurance company, files a statement of claim containing any false, incomplete, or misleading information is subject to prosecution and punishment for insurance fraud, as provided by RSA 638.20",
  },
  {
    state: "New Jersey",
    text: "Any person who knowingly files a statement of claim containing any false or misleading information is subject to criminal and civil penalties.",
  },
  {
    state: "New Mexico",
    text: "Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to civil fines and criminal penalties.",
  },
  {
    state: "New York",
    text: "Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of the claim for each such violation.",
  },
  {
    state: "North Carolina",
    text: "Any person with the intent to injure, defraud, or deceive an insurer or insurance claimant is guilty of a crime (Class H felony) which may subject the person to criminal and civil penalties.",
  },
  {
    state: "Ohio",
    text: "Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.",
  },
  {
    state: "Oklahoma",
    text: "WARNING: Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.",
  },
  {
    state: "Oregon",
    text: "Any person who knowingly, and with intent to defraud any insurance company or other persons files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto, may be subject to prosecution for insurance fraud.",
  },
  {
    state: "Pennsylvania",
    text: "Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.",
  },
  {
    state: "Puerto Rico",
    text: "Any person who knowingly and with the intention of defrauding presents false information in an insurance application, or presents, helps, or causes the presentation of a fraudulent claim for the payment of a loss or any other benefit, or presents more than one claim for the same damage or loss, shall incur a felony and, upon conviction, shall be sanctioned for each violation with the penalty of a fine of not less than five thousand ($5,000) and not more than ten thousand ($10,000), or a fixed term of imprisonment for three (3) years, or both penalties. Should aggravating circumstances be present, the penalty thus established may be increased to a maximum of five (5) years, if extenuating circumstances are present, it may be reduced to a minimum of two (2) years.",
  },
  {
    state: "Texas",
    text: "Any person who knowingly presents a false or fraudulent claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.",
  },
  {
    state: "Virginia",
    text: "Any person who, with the intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement may have violated state law.",
  },
];

export default disclaimers;
