export function login(
  token: string,
  email: string,
  isAuthorized: boolean,
  flow: string
) {
  const item = {
    value: token,
    email,
    isAuthorized,
    flow,
  };
  localStorage.setItem("session", JSON.stringify(item));
}

export function getUserToken() {
  const itemStr = localStorage.getItem("session");

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  return item.value;
}

export function getFlow() {
  const itemStr = localStorage.getItem("session");

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  return item.flow;
}

export function getIsAuthorized() {
  const itemStr = localStorage.getItem("session");

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  return item.isAuthorized;
}

export function getInsuranceType() {
  const itemStr = localStorage.getItem("session");

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  return item.insuranceType;
}

export function getUserEmail() {
  const itemStr = localStorage.getItem("session");

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  return item.email;
}

export function isTokenValid() {
  return getUserToken() != null && getIsAuthorized() == true;
}
