import router from "@/router";
import { defineComponent, ref } from "vue";
import VOtpInput from "vue3-otp-input";
import { Auth } from "aws-amplify";
import { getFlow, getUserEmail, getUserToken, login } from "@/utils/auth";
import BinkeyLogo from "@/components/common/BinkeyLogo.vue";

export default defineComponent({
  inject: { error: { from: "show-error-notification", default: null } },

  async onBeforeUnmount() {
    // Clear the interval when the component is unmounted
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    window.removeEventListener("resize", this.handleResize);
  },

  async mounted() {
    window.addEventListener("resize", this.handleResize);

    this.isMobile = window.innerWidth <= 500;

    this.canResend = false;
    this.startCountdown();

    try {
      // Access the email query parameter from the route
      this.email = await getUserEmail();
      if (this.email && !this.user) {
        const email = this.email.toLowerCase();
        this.user = await Auth.signIn(email);
        login(getUserToken(), email, true, getFlow());
      }
    } catch {
      console.log("Server error");
    }
  },
  components: {
    VOtpInput,
    BinkeyLogo,
  },
  data() {
    const sheetWidth = ref(calculateWidth());

    return {
      sheetWidth,
      loading: false,
      email: "",
      securityCode: "",
      validOTP: true,
      canContinue: false,
      isMobile: false,
      user: undefined,
      retry: 1,
      resendMessage: "Resend code",
      canResend: true,
      secondsRemaining: 60, // Set the initial countdown time in seconds
      countdownInterval: null as NodeJS.Timeout | null, // Initialize the countdown interval property
    };
  },
  computed: {
    countdown() {
      return this.secondsRemaining;
    },
  },
  methods: {
    async onSubmit() {
      this.loading = true;

      if (this.retry >= 5) {
        this.showError("Code has expired, please request a new code");
        this.loading = false;
        this.validOTP = false;
        this.securityCode = "";
        return;
      }

      await Auth.sendCustomChallengeAnswer(this.user, this.securityCode)
        .then(async (response) => {
          if (response.attributes) {
            this.$posthog.identify(response.attributes.email, {
              email: response.attributes.email,
              name: response.attributes.name,
              phone_number: response.attributes.phone_number,
              user_id: response.attributes.sub,
            });
            this.$posthog.capture("user_logged_in_successfully");
            await router.push({ name: "vision" });
          } else {
            let message = "Invalid code, please check and try again";
            if (this.retry > 2) {
              const tries = 5 - this.retry;
              message = `Invalid code, you have ${tries} ${
                tries === 1 ? "try" : "tries"
              } remaining`;
            }
            this.$posthog.capture("user_logged_in_failed");
            this.showError(message);
            this.retry += 1;
          }
        })
        .catch(() => {
          this.validOTP = false;
          this.showError("Code has expired, please request a new code");
        });

      this.loading = false;
      this.securityCode = "";
    },
    async reSendCode() {
      try {
        if (this.email) {
          this.canContinue = true;
          const email = this.email.toLowerCase();
          this.user = await Auth.signIn(email);
        }
      } catch {
        this.showError("Email not registered");
      }
      this.secondsRemaining = 60;
      this.canResend = false;
      this.retry = 1;
      this.securityCode = "";
      this.startCountdown();
    },
    startCountdown() {
      // Use setInterval to update the countdown every second
      this.countdownInterval = setInterval(() => {
        if (this.secondsRemaining <= 0) {
          if (this.countdownInterval) {
            clearInterval(this.countdownInterval); // Clear the interval
            this.resendMessage = "Resend code";
            this.canResend = true;
          }
          // You can add code here to perform an action when the countdown reaches 0.
        } else {
          this.secondsRemaining--;
          this.resendMessage = this.secondsRemaining.toString();
        }
      }, 1000);
    },

    handleResize() {
      // Handle the resize event here, you can call your method
      this.sheetWidth = calculateWidth();
    },

    showError(message: string) {
      this.error && (this.error as (s: string) => void)(message);
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
    },
  },
  watch: {
    securityCode(newCode) {
      if (newCode.length === 6) {
        this.canContinue = true;
      } else {
        this.canContinue = false;
      }
    },
  },
});
function calculateWidth() {
  // Check if window is defined
  // Access window.innerWidth directly
  const screenWidth = ref(window.innerWidth);

  if (screenWidth.value <= 700) {
    return "90%";
  } else if (screenWidth.value <= 1000) {
    return "80%";
  } else if (screenWidth.value <= 1300) {
    return "70%";
  } else {
    return "60%";
  }
}
