import jsPDF from "jspdf";

export function fileToDataUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        resolve(e.target?.result as string);
      } else {
        reject(new Error("Failed to read file as URL"));
      }
    };
    reader.readAsDataURL(file);
  });
}

export function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Response(blob).arrayBuffer();
}

export async function createFileFromBinaryContent(
  binaryContent: Uint8Array,
  fileName: string,
  type: string
): Promise<File> {
  const blob = new Blob([binaryContent], { type });
  return new File([blob], fileName, { type });
}

export async function urlToArrayBuffer(url: string): Promise<ArrayBuffer> {
  return (await fetch(url)).arrayBuffer();
}

export async function base64ToArrayBuffer(base64: string, type: string) {
  const dataUrl = `data:${type};base64,${base64}`;
  return (await fetch(dataUrl)).arrayBuffer();
}

export async function base64ToBlob(base64: string, type: string) {
  const dataUrl = `data:${type};base64,${base64}`;
  return (await fetch(dataUrl)).blob();
}

export async function base64ToFile(
  base64: string,
  type: string,
  fileName: string
) {
  const blob = await base64ToBlob(base64, type);
  return new File([blob], fileName, { type });
}

export function dataUrlToInfo(dataUrl: string) {
  const [info, content] = dataUrl.split(",");
  const [type] = info.split(";");
  const fileExtension = type.split("/")[1];

  return { fileExtension, content };
}

export async function htmlToPdfDataUrl(html: string): Promise<string> {
  const pdf = new jsPDF("p", "pt", "letter");
  return new Promise((resolve, reject) => {
    try {
      pdf.html(html, {
        callback: (pdf: jsPDF) => {
          resolve(pdf.output("dataurlstring"));
        },
      });
    } catch {
      reject();
    }
  });
}
