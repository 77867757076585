
import { defineComponent } from "vue";
import SignaturePad from "signature_pad";

export default defineComponent({
  emits: ["update:modelValue"],
  props: { modelValue: String },

  computed: {
    value: {
      get(): string | undefined {
        return this.modelValue;
      },
      set(v: string | undefined) {
        this.$emit("update:modelValue", v);
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      const canvas = this.$refs.canvas as HTMLCanvasElement;
      const width = window.innerWidth * 0.3;

      if (width < 200) {
        const width = window.innerWidth - 70;
        canvas.width = width;
      } else {
        const maxWidth = 550;
        canvas.width = Math.min(width + 100, maxWidth);
      }

      canvas.height = canvas.width / 2;

      const signaturePad = new SignaturePad(canvas, { minWidth: 2 });
      signaturePad.addEventListener("endStroke", () => {
        this.value = signaturePad.toDataURL();
      });
    });
  },

  methods: {
    clear() {
      this.value = undefined;
      const canvas = this.$refs.canvas as HTMLCanvasElement;
      const signaturePad = new SignaturePad(canvas, { minWidth: 2 });
      signaturePad.clear();
    },
  },
});
