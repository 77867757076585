import posthog from "posthog-js";

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install(app: any) {
    app.config.globalProperties.$posthog = posthog.init(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      process.env.VUE_APP_POSTHOG_KEY!,
      {
        api_host: "https://app.posthog.com",
      }
    );
  },
};
