<template>
  <div class="d-flex justify-center align-center text-center h-screen">
    <div>
      <h2 class="text-primary">Not found</h2>
      <h3>Ops! the page you are looking for doesn't exist.</h3>
      <h3>
        If you have any issues, please contact
        <span class="text-primary">support@binkey.com</span>
      </h3>
    </div>
  </div>
</template>
