const en = {
  login: {
    title: "Let's get your reimbursement started.",
    subtitle: "Binkey uses your email for login.",
    error: "Invalid credentials",
    continue: "Continue",
    minCharactersError: "Password must be at least 8 characters long",
    specialCharacterError:
      "Password must contain at least one special character",
    uppercaseError: "Password must contain at least one uppercase letter",
    digitError: "Password must contain at least one digit",
  },
  form: {
    accountTitle: "Let's create your claim for reimbursement",
    subscriberTitle: "Tell us about the Subscriber",
    patientTitle: "Since this is your first claim, we need more info",
    claimTitle: "Claim Information",
    reviewTitle: "Review",
    next: "Next",
    back: "Back",
    note: "Missing or incomplete/incorrect information may result in a delay in payment.",
    name: "Fisrt Name",
    lastname: "Last Name",
    error: "Select a user type (Subscriber, Patient or Both).",
    thankYou: "Your claim has been submitted",
    submit: "Submit",
  },
  account: {
    title: "Let's create your claim for reimbursement",
    name: "What is your name?",
    subscriberTitle: "Are you subscriber, patient or both?",
    subscriber: "Subscriber",
    patient: "Patient",
    subscriberAndPatient: "Both",
    orderTitle:
      "We see you have a previous Pay with Binkey order, which order is the claim related to?",
    error: "Please",
    next: "Next",
    minCharactersError: "Password must be at least 8 characters long",
    specialCharacterError:
      "Password must contain at least one lowercase letter",
    uppercaseError: "Password must contain at least one uppercase letter",
    digitError: "Password must contain at least one digit",
    mdiIcon: "mdi mdi-information",
  },
  subscriber: {
    selectInsurance: "Select your vision insurance",
    selectFSAInsurance: "Select your FSA insurance",
    search: "Search",
    notListed: "Mine is not listed",
    back: "Back",
    next: "Next",
    textInput: "Please enter the name of your insurance carrier",
    insurancePlan: "Insurance plan",
    thankYou: "Thank you for using Binkey",
    notSupported:
      "Currently we do not support {input}. We continue to support new insurance carriers and will let you know when we add support.",
    information: "Subscriber information",
    firstName: "First name",
    lastName: "Last name",
    initial: "Initial",
    gender: "Gender",
    dateOfBirth: "Date of Birth (mm/dd/yyyy)",
    phonePlaceholder: "(   ) --- -----",
    address: "Address",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
    title0: "Let's create claim fot reimbursement.",
    validations: {
      name: "First name is required",
      lastName: "Last name is required",
      middleInitial: "Middle Initial",
      idSSN: "Identification Number or SSN is required",
      gender: "Gender is required",
      phoneNumber: "Number must be 7 digits",
      birthDate: "Date of birth is required",
      address: "Address 1 is required",
      homeAddress: "Home Address is required",
      city: "City is required",
      state: "State is required",
      zip: "Zip Code is required",
      serviceDate: "Service date is required",
      streetAddress: "Street Address is required",
      postalCode: "Postal Code is required",
      telephoneAreaCode: "Area Code is required",
      telephone: "Number must be 7 digits",
      sexOption: "Sex is required",
      employerName: "Employer Name is required",
      insuranPlanName: "Insurance Plan Name is required",
      subscriberGroupName: "Subscriber's Group Name is required",
      phoneCode: "Code must be 3 digits",
      ssnDigits: "Last 4 Digits of SSN are required",
      groupName: "Group Name is required",
      group: "Group is required",
      memberID: "Member Identification No.",
    },
    genreOptions: {
      male: "Male",
      female: "Female",
    },
    sexOptions: {
      male: "M",
      female: "F",
    },
  },
  review: {
    patientInfo: "Patient information",
    subscriberInfo: "Subscriber information",
    name: "Name",
    birth: "Date of Birth",
    relation: "Relation to Subscriber",
    patientAddress: "Patient address",
    plan: "Insurance Plan Name",
    firstName: "First name",
    lastName: "Last name",
    initial: "Initial",
    gender: "Gender",
    phone: "Phone Number",
    address: "Address",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
    serviceSummary: "Service Summary",
    date: "Date",
    merchantId: "Merchant ID",
    merchantPhone: "Merchant Phone Number",
    merchantName: "Merchant Name",
    merchantAddress: "Merchant Address",
    line1: "Line 1",
    line2: "Line 2",
    total: "Total Reimbursement",
    receipt: "Receipt",
    revisit: "Revisit Section",
  },
  patient: {
    patientSubtitle: "Patient Information",
    patientRelation: "Relation to Subscriber",
    address1: "Address",
    address2: "Address 2",
    patientCheck: "Patient address is the same as the subscriber",
    error: "Please fill in the required fields.",
    validations: {
      name: "First name is required",
      lastName: "Last name is required",
      middleInitial: "Middle Initial",
      idSSN: "Identification Number or SSN is required",
      gender: "Gender is required",
      phoneNumber: "Number must be 7 digits",
      birthDate: "Date of birth is required",
      address: "Address 1 is required",
      city: "City is required",
      state: "State is required",
      zip: "Zip Code is required",
      serviceDate: "Service date is required",
      streetAddress: "Street Address is required",
      postalCode: "Postal Code is required",
      telephoneAreaCode: "Area Code is required",
      telephone: "Number must be 7 digits",
      sexOption: "Sex is required",
      employerName: "Employer Name is required",
      insuranPlanName: "Insurance Plan name is required",
      subscriberGroupName: "Subscriber's Group Name is required",
      subscriberGroup: "Subscriber's Group is required",

      PatientRelationship: "Patient Relationship  is required",
      patientStatus: "Patient Status is required",
      patientCondition: "Condition Related question is required",
      otherPlan: "Other Health Plan question is required.",
      phoneCode: "Code must be 3 digits",
      policyholderRelationship: "Policyholder Relationship is required",
      relationSubs: "Relationship to the Subscriber is required",
      providerName: "Provider Name is required",
    },
    relationsOptions: {
      self: "Self",
      dependent: "Dependent",
    },
  },
  security: {
    title: "Bzz Bzzz, did you get our email?",
    code: "Enter the code we sent to ",
    resend: "Resend code",
    success: "Success!",
    check: "Check your email for your new code.",
    close: "Close",
    different: "Use a different email address",
    continue: "Continue",
    agree: "By verifying my email address, I agree to Binkey",
    terms: "Terms of Service",
    and: "and",
    privacy: "Privacy Policy",
  },
  finalReview: {
    insuranceDisclaimer: "Insurance Disclaimer",
    acknowledgementTitle: "Acknowledgement",
    acknowledgement1: `Missing or incomplete/incorrect information may result in a delay in
      payment.`,
    acknowledgement2: `Any person who, with intent to defraud or knowing that he is
      facilitating a fraud against an insurer, submits an application or
      files a claim containing a false or deceptive statement is guilty of
      insurance fraud and may be subject to fines and confinement in
      prison.`,
    clear: "Clear",
  },
  thankYou: {
    message: "Thank you!",
    button: "See Claim Status",
  },
  claim: {
    error:
      "Please enter the date of service, indicate which service, and upload the receipt.",
    validations: {
      totalAmount: "Total Amount is required",
      name: "First name is required",
      lastName: "Last name is required",
      officeName: "Office Name is required",
      address: "Address is required",
      address2: "Address 2 is required",
      city: "City is required",
      state: "State is required",
      zip: "Zip Code is required",
      dateOfService: "Date of service is required",
    },
  },
  options: {
    genreOptions: {
      male: "Male",
      female: "Female",
    },
    titleOptions: {
      md: "MD",
      do: "DO",
      od: "OD",
    },
    glassesOrContacts: {
      glasses: "Glasses",
      contacts: "Contacts",
    },
    patientRealationships: {
      self: "Self",
      spouse: "Spouse",
      child: "Child",
      other: "Other",
      student: "Student",
      handicapped: "Handicapped",
      domesticPartner: "Domestic Partner",
      policyholder: "Policyholder",
    },
    relatedCondition: {
      employment: "Employment",
      autoAccident: "Auto Accident",
      other: "Other Accident",
    },
    patientStatus: {
      employed: "Employed",
      fullTimeStudent: "Full Time Student",
    },
    lensType: {
      single: "Single-Vision",
      bifocal: "Bifocal",
      trifocal: "Trifocal",
      progressive: "Progressive",
      lenticular: "Lenticular",
      contacts: "Contacts",
    },
    yesNoOptions: {
      yes: "Yes",
      no: "No",
    },
    cardholder: {
      active: "Active",
      retired: "Retired",
      hourly: "Hourly",
      salaried: "Salaried",
    },
    lensOptions: {
      tint: "Tint",
      other: "Other",
    },
    serviceType: {
      glasses: "Glasses",
      contacts: "Contacts",
    },
    groupPlan: {
      shelterPointStandAlone: "ShelterPoint StandAlone",
      shelterPointXGMM: "ShelterPointXGMM",
      WEA: "WEA",
      none: "None",
    },
    resultOptions: {
      corrected: "Corrected",
      uncorrected: "Uncorrected",
    },
  },
  start: {
    error:
      "It seems that the link you're trying to access is either invalid or the session associated with it has already expired. Please access with your link again, if the issue persists, we recommend that you get in touch with our support team for further assistance:",
    errorSessionAlreadySubmited:
      "The reimbursement claim for this purchase has already been submitted. For any assistance, please contact ",
  },
};
export default en;
