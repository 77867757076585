enum VisionFormSteps {
  PersonalInformation,
  InsuranceInformation,
  ClaimInformation,
  Review,
  FinalReview,
  Confirmation,
}

export default VisionFormSteps;
